<template>
  <div class="login d-flex justify-center align-center">
    <div class="d-flex container-login">
      <div class="temp-login">
        <div class="in-cover">
          <nav class="right text-center mt-5">
            <img
              src="../assets/img/logo-lan-warna.png"
              height="70px"
              alt="logo"
            />
          </nav>
          <div class="auth-form">
            <div class="temp">
              <section class="mb-3">
                <h1>Atur ulang kata sandi</h1>
              </section>
              <form @submit.prevent="submit">
                <v-text-field
                  v-model="password"
                  label="Kata sandi baru"
                  counter
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show ? 'text' : 'password'"
                  :error-messages="PasswordError"
                  @input="$v.password.$touch()"
                  @blur="$v.password.$touch()"
                  @click:append="show = !show"
                ></v-text-field>
                <v-text-field
                  v-model="passwordConfirm"
                  label="Konfirmasi kata sandi"
                  counter
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show2 ? 'text' : 'password'"
                  @click:append="show2 = !show2"
                  :error-messages="PasswordConfirmError"
                  @input="$v.passwordConfirm.$touch()"
                  @blur="$v.passwordConfirm.$touch()"
                ></v-text-field>
                <div class="button-group mt-5 d-flex flex-column">
                  <v-btn class="mb-3" color="#1a237e" dark v-if="loading">
                    <span class="d-flex">
                      <Loading propsColor="white" /> Silakan tunggu ...
                    </span>
                  </v-btn>
                  <v-btn class="mb-3" type="submit" color="#1a237e" dark>
                    <span> atur ulang </span>
                  </v-btn>
                </div>
              </form>
              <!-- snackbar notification -->
              <v-snackbar v-model="snack" color="red" top>
                {{ errMsg }}
                <template v-slot:action="{ attrs }">
                  <v-btn color="white" text v-bind="attrs" @click="closeSnack">
                    Close
                  </v-btn>
                </template>
              </v-snackbar>
            </div>
          </div>
        </div>
      </div>
      <div class="cover-auth">
        <nav class="text-center mt-5">
          <img
            src="../assets/img/logo-lan-putih.png"
            height="70px"
            alt="logo"
          />
        </nav>
        <div class="crousel-temp pa-2"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import Loading from "../components/_base/loader";

export default {
  components: {
    Loading
  },
  mixins: [validationMixin],
  validations: {
    password: { required, minLength: minLength(8) },
    passwordConfirm: { required, sameAsPassword: sameAs("password") }
  },
  computed: {
    ...mapState(["errMsg", "snack", "loading"]), //get State from vuex(Store)
    PasswordError() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.minLength &&
        errors.push("Kata sandi minimal 8 karakter!");
      !this.$v.password.required && errors.push("Kata sandi harus di isi!");
      return errors;
    },
    PasswordConfirmError() {
      const errors = [];
      if (!this.$v.passwordConfirm.$dirty) return errors;
      !this.$v.passwordConfirm.sameAsPassword &&
        errors.push("kata sandi tidak sama!");
      !this.$v.passwordConfirm.required &&
        errors.push("Kata sandi harus di isi!");
      return errors;
    }
  },
  watch: {
    snack(val) {
      console.log(val);
    },
    loading(val) {
      console.log(val);
    }
  },
  data: () => ({
    name: "",
    dialog: false,
    password: "",
    passwordConfirm: "",
    snackbar: false,
    show: false,
    show2: false,
    model: 0,
    token: "",
    email: ""
  }),
  mounted() {
    this.token = this.$route.query.token;
    this.email = this.$route.query.email;
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.$store
          .dispatch("resetPassword", {
            email: this.email,
            token: this.token,
            password: this.password,
            password_confirmation: this.passwordConfirm
          })
          .then(res => {
            console.log(res);
            this.loading = true;
            this.randomColor();
            this.$router.push("/login");
          })
          .catch(error => {
            return error;
          });
      }
    },
    randomColor() {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      localStorage.setItem("xYzvQ", color);
    },
    dialogFalse() {
      this.dialog = false;
    },
    closeSnack() {
      this.$store.commit("SNACKY", false);
    }
  }
};
</script>

<style>
.login {
  height: 100vh;
  background: #e3e7ed;
}
.btn-primary {
  background: #1a237e;
}
.container-login {
  height: 70vh;
  width: 60%;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 0px 18px 5px rgba(0, 0, 0, 0.17);
}
.temp-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #1a237e;
  border-radius: 20px 0 0 20px;
  width: 50%;
}
.cover-auth {
  background: #1a237e;
  box-sizing: border-box;
  width: 50%;
}
.crousel-temp {
  display: flex;
  align-items: center;
  width: 100%;
}
.carousel-card {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  height: 150px;
  width: 250px;
  border-radius: 8px;
}
.overflow-img {
  z-index: 2;
  position: relative;
  top: -50px;
  left: -10px;
}
.overflow-img img {
  transform: rotate(20deg);
  position: absolute;
}
.right {
  display: none;
}
.in-cover {
  width: 100%;
  background: #fff;
  height: 100%;
  border-radius: 15px;
  z-index: 3;
  box-shadow: 17px 0px 27px 0px rgba(0, 0, 0, 0.25);
}
.auth-form {
  display: flex;
  justify-content: center;
}
.temp {
  background: #fff;
  width: 90%;
  height: 300px;
  padding: 20px;
  border-radius: 5px;
}
.forgot-pwd {
  cursor: pointer;
}
.forgot-pwd:hover {
  color: #1a237e;
}
@media (max-width: 700px) {
  .right {
    display: block;
  }
  .cover-auth {
    display: none;
  }
  .temp-login {
    width: 100%;
    background: none;
  }
  .container-login {
    width: 90%;
  }
}
</style>
